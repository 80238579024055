@import '~@styles/colors.scss';

.logoutPanel {
  color: $white;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  right: 10px;
  top: 10px;
  overflow: visible;
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 5px;
    box-shadow: $shadow50low;
    z-index: 99;
  }
  .username {
    margin-right: 5px;
  }
  .logout {
    cursor: pointer;
    position: absolute;
    left: 0;
    transition: transform 0.2s ease-in-out, opacity 0.2s linear;
    z-index: 9;
    background-color: $white;
    border-radius: 5px;
    color: $black;
    padding: 5px 10px;
    opacity: 0;
  }
  &:hover {
    .logout {
      transform: translateX(-100%);
      opacity: 1;
    }
  }
}
