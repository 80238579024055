@import "~@styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.app {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  background: $pinkgradient;
  input {
    background: none;
    outline: 0;
    border: none;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  .content {
    min-width: 600px;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    a {
      text-decoration: none;
      color: $grey;
      font-size: 12px;
    }
  }
}
