@import "~@styles/colors.scss";

.fieldList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .buildID {
      color: $white;
      font-size: 15px;
      font-weight: 900;
      font-size: 25px;
      .viewCreative,
      .return {
        text-decoration: none;
        font-size: 15px;
        color: $white;
        font-weight: 300;
      }
      .return {
        position: fixed;
        left: 20px;
        top: calc(50% - 25px);
        background-color: rgba(225, 225, 225, 0.5);
        border: 2px solid $white;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: $grey;
          border-color: $grey;
        }
      }
      margin-bottom: 30px;
    }
    .fields {
      .field {
        display: flex;
        border-radius: 50px;
        background: $white;
        margin-bottom: 20px;
        align-items: center;
        .delete {
          color: $white;
          background: $lightgrey;
          height: 40px;
          width: 40px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-right: 20px;
          &:hover {
            background: $pink;
            box-shadow: $shadow50low;
          }
        }
        label {
          display: flex;
          font-size: 12px;
          align-items: center;
          padding: 20px;
          width: 50%;
          p {
            width: auto;
            white-space: nowrap;
            font-family: "Roboto Mono";
            color: $lightgrey;
            margin: 0 10px 0 0;
            line-height: 40px;
          }
          span {
            font-style: italic;
            line-height: 40px;
            color: $lightgrey;
            margin-right: 5px;
          }
          input {
            font-size: 15px;
            font-family: "Roboto Mono";
            font-style: italic;
          }
        }
      }
    }
    .addNewField {
      height: 80px;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      border: 2px dotted $white;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;
      user-select: none;
      cursor: pointer;
      svg {
        margin-right: 10px;
      }
    }
    .save {
      border-radius: 50px;
      margin-top: 10px;
      height: 60px;
      padding: 0 40px;
      user-select: none;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $pinkToGreyGradient;
      svg {
        margin-right: 10px;
      }
      &.active {
        color: $white;
        cursor: pointer;
      }
      &.inactive {
        color: $lightgrey;
        background: $grey;
        cursor: not-allowed;
      }
    }
    .lastUpdated {
      font-size: 12px;
      color: $white;
      margin-top: 20px;
      p {
        margin: 0px;
      }
    }
  }
  .admin {
    width: 50%;
  }
  .permission {
    background: $white;
    padding: 0px 40px 20px 40px;
    border-radius: 50px;
    h1 {
      border-bottom: 1px solid $black25;
      font-size: 18px;
      line-height: 60px;
      text-transform: uppercase;
    }
  }
}
