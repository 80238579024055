@import "~@styles/colors.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  background: $white;
  box-shadow: $shadow50high;
  color: $black;
  margin-bottom: 50px;
  font-style: italic;
  font-weight: 900;
  padding: 20px;
  text-transform: uppercase;
  span {
    margin-right: 5px;
    color: $pink;
  }
}
