$black: #252b2c;
$white: #f3f3f3;
$grey: #3e474a;
$lightgrey: #8b9192;
$lightergrey: #dddddd;
$pink: #fe0060;
$orange: #ff8a3f;
$darkgrey: #252b2c;
$lvl2-grey: #2a3132;
$darkpink: #b62456;
$vsgreen: #0b9f90;
$yellow: #ffc800;
$blue: #0037ff;
$confirmgreen: #79b473;
$pinkgradient: linear-gradient(135deg, #fe0060 0%, 75%, #ff8a3f 100%);
$orbgradient: linear-gradient(-90deg, #fe0060 25%, 60%, #ff8a3f 100%);
$darkpinkgradient: linear-gradient(
  90deg,
  #b62456 0%,
  #fe0060 50%,
  #ff8a3f 100%
);
$pinkToGreyGradient: linear-gradient(90deg, $grey 0%, 50%, $darkpink 100%);
$pink10: rgba(254, 0, 96, 0.1);
$pink50: rgba(254, 0, 96, 0.1);

$black5: rgba(0, 0, 0, 0.05);
$black10: rgba(0, 0, 0, 0.1);
$black25: rgba(0, 0, 0, 0.25);
$black50: rgba(0, 0, 0, 0.5);

$white10: rgba(255, 255, 255, 0.1);
$white50: rgba(255, 255, 255, 0.5);
$white80: rgba(255, 255, 255, 0.8);

$shadow25low: 0px 0px 4px $black25;
$shadow25med: 0px 0px 8px $black25;
$shadow25high: 0px 0px 16px $black25;
$shadow50low: 0px 0px 4px $black50;
$shadow50med: 0px 0px 8px $black50;
$shadow50high: 0px 0px 16px $black50;
