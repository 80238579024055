@import "~@styles/colors.scss";

.notification {
  color: $white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  text-align: center;
  position: fixed;
  width: 80%;
  height: 60px;
  bottom: 20px;
  left: 10%;
  background: $vsgreen;
  transform: translateY(80px);
  transition: all 0.2s ease-in-out;
  z-index: 99;
  box-shadow: $shadow50high;
  border-radius: 50px;
  &.active {
    transform: translateY(0px);
  }
}
