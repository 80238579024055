@import '~@styles/colors.scss';

.loginScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $grey;
  .loginWrapper {
    background: $white;
    box-shadow: $shadow50med;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    padding: 50px;
  }
}
