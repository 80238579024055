@import "~@styles/colors.scss";
.admin {
  background: $black;
  width: 400px;
  height: calc(100% - 60px - 40px);
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 0;
  box-shadow: $shadow50med;
  border-radius: 50px;
  color: $white;
  padding: 0 20px;
  overflow-y: auto;
  h1 {
    font-size: 18px;
    border-bottom: 1px solid $white10;
    line-height: 60px;
    margin: 20px 0;
  }
  .user {
    display: flex;
    border-radius: 50px;
    background: $white;
    margin-bottom: 20px;
    border: 2px solid $white;
    &.invalid {
      border: 2px solid $pink;
      background: $white80;
    }
    label {
      display: flex;
      font-size: 12px;
      align-items: center;
      padding: 20px;
      width: 100%;
      p {
        width: auto;
        white-space: nowrap;
        font-family: "Roboto Mono";
        color: $lightgrey;
        margin: 0 10px 0 0;
        line-height: 40px;
      }
      span {
        font-style: italic;
        line-height: 40px;
        color: $lightgrey;
        margin-right: 5px;
      }
      input {
        font-size: 15px;
        font-family: "Roboto Mono";
        font-style: italic;
      }
      .delete {
        color: $white;
        background: $lightgrey;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: $pink;
          box-shadow: $shadow50low;
        }
      }
    }
  }
  .addNewField {
    height: 80px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 2px dotted $white;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    user-select: none;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
  }
  .save {
    border-radius: 50px;
    margin-top: 10px;
    height: 60px;
    padding: 0 40px;
    user-select: none;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $pinkgradient;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      margin-right: 10px;
    }
    &:hover {
      box-shadow: $shadow50high;
      transform: translate(-1px, -1px);
    }
    &.inactive {
      cursor: not-allowed;
      pointer-events: none;
      background: $grey;
      color: $lightgrey;
    }
  }
}
