@import "~@styles/colors.scss";

.buildIDWrapper {
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: space-around;
  border-radius: 50px;
  box-shadow: $shadow25med;
  color: $lightgrey;
  background: $white;
  &.invalid {
    animation: shakeX 0.5s 0s 1;
  }
  &:hover {
    box-shadow: $shadow25high;
    border-color: transparent;
    color: $grey;
  }
  .searchBar {
    width: 100%;
    height: 60px;
  }
  .next,
  .icon {
    height: 40px;
    width: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 0 10px;
  }
  .next {
    width: 60px;
    cursor: pointer;
    margin: 0 0 0 15px;
    padding: 10px 20px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $grey;
      color: $white;
    }
  }
  @keyframes shakeX {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      transform: translate3d(10px, 0, 0);
    }
  }

  .shakeX {
    animation-name: shakeX;
  }
}
